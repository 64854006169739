import ParentCheckoutForm from "components/pages/Checkout/components/ParentCheckoutForm"
import ParentSignupForm from "components/pages/Checkout/components/ParentSignupForm"
import { graphql } from "gatsby"
import React, { useState } from "react"
import styled from "styled-components"
import SEO from "../components/SEO"
import useLocale from "../hooks/useLocale"
import { CheckoutLayout } from "../layouts/CheckoutLayout"

const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 600px;
`

export interface IParentAccountData {
  stripeCustomerId: string
  parent: {
    _id: string
    email: string
    name: string
    referralCode: string
    subscription: {
      id: string
      customer: {
        id: string
      }
    }
  }
  hash: string
  kids: {
    _id: string
    username: string
    yearGroup: number
    className: string
  }[]
}

interface IProps {
  pageContext: {
    specialOffer: boolean
  }
  data: {
    file: any
    stripePrice: {
      id: string
      currency: string
      nickname: string
      product: {
        metadata: {
          includesResources: string | null
        }
      }
      recurring: {
        interval: "month" | "year"
        interval_count: number
        trial_period_days: number
      }
      tiers: {
        up_to: number
        flat_amount: number
        unit_amount: number
      }[]
      trial_period_days: number
    }
  }
}

export interface ICheckoutFormData {
  firstName: string
  lastName: string
  email: string
  countryCode: string
  termsAccepted: boolean
  marketingAccepted: boolean
  childrenAges: any[]
  price: string
  quantity: number
}

const CheckoutPageTemplate = (props: IProps) => {
  const { data } = props
  const [step, setStep] = useState("DETAILS")
  // const [step, setStep] = useState("CHECKOUT")
  const { countryCode } = useLocale()
  const [formData, setFormData] = useState<ICheckoutFormData>({
    firstName: "",
    lastName: "",
    email: "",
    countryCode,
    termsAccepted: false,
    marketingAccepted: false,
    childrenAges: [null],
    price: data.stripePrice.id,
    quantity: 1,
  })

  return (
    <CheckoutLayout>
      <>
        <SEO
          title="Checkout"
          description={`Night Zookeeper parent checkout for plan ${
            data.stripePrice.nickname
          }${
            data.stripePrice.trial_period_days || 7
              ? ` with a ${
                  data.stripePrice.trial_period_days || 7
                } day free trial!`
              : `!`
          } Price starting`}
          titleTemplate="%s | Night Zookeeper"
          noIndex
        />
        <Content>
          {step === "DETAILS" && (
            <ParentSignupForm
              price={data.stripePrice}
              formData={formData}
              setFormData={setFormData}
              onSubmit={() => {
                setStep("CHECKOUT")
              }}
            />
          )}
          {step === "CHECKOUT" && (
            <ParentCheckoutForm
              // @ts-ignore
              price={data.stripePrice}
              formData={formData}
              specialOffer={Boolean(props.pageContext.specialOffer)}
              setFormData={setFormData}
              onBack={() => setStep("DETAILS")}
            />
          )}
        </Content>
      </>
    </CheckoutLayout>
  )
}

export const query = graphql`
  query getPlan($planId: String!) {
    stripePlan(id: { eq: $planId }) {
      id
      currency
      interval
      interval_count
      amount
      nickname
      trial_period_days
    }
    stripePrice(id: { eq: $planId }) {
      id
      currency
      nickname
      recurring {
        interval
        interval_count
        trial_period_days
      }
      product {
        id
        metadata {
          includesResources
        }
      }
      tiers {
        up_to
        flat_amount
        unit_amount
      }
    }
  }
`

export default CheckoutPageTemplate
