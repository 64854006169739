import { formatAmount } from "components/Strapi/StrapiPlanHero"
import React, { useMemo } from "react"
import styled, { css } from "styled-components"
import { ICoupon, StripePrice } from "../../types"
import {
  getAmountOff,
  getCorrectTier,
  getReadableInterval,
} from "../SelectedPlan/utils"

interface IProps {
  price: StripePrice
  quantity: number
  couponObject?: ICoupon

  expanded?: boolean
}

const Wrapper = styled.div<{ $expanded: boolean }>`
  h2 {
    text-align: center;
    color: #6b259a;
    padding: 0px 0;
    font-size: 20px;
    margin: 10px 0 5px 0;
  }
  > * {
    padding: 15px 0;
  }
  > :nth-child(n):not(:first-child):not(:last-child) {
    position: relative;
    ::after {
      position: absolute;
      content: "";
      width: 100%;
      bottom: 0;
      left: 0;
      height: 1px;
      background-color: #ebebeb;
    }
  }
  > .invoice {
    > .invoice--row {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;
      font-size: 14px;
      > .invoice--row-label {
      }
      > .invoice--row-price {
      }
    }
    .invoice--row.total {
      font-size: 16px;
      > .invoice--row-price {
        color: #019be0;
      }
      font-weight: bold;
    }
  }
  .check-icon {
    height: 18px;
    width: 18px;
    background-image: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none"><path d="M6.66358 7.62818C6.37812 7.29489 5.87608 7.25553 5.54209 7.54099C5.2088 7.82646 5.16943 8.32849 5.4549 8.66248L7.93206 11.5531L8.53605 11.0363L7.93066 11.5552C8.21682 11.8892 8.72026 11.9279 9.05495 11.6417C9.07675 11.6234 9.09714 11.6037 9.11613 11.5833L17.4193 3.85521C17.7407 3.55638 17.759 3.05225 17.4594 2.73092C17.1606 2.40958 16.6565 2.39131 16.3351 2.69084L8.59999 9.8894L6.66211 7.62878L6.66358 7.62818Z" fill="%234DB062"/><path d="M9.10547 2.11719C10.8373 2.11719 12.4171 2.77039 13.6111 3.84407L12.4931 4.98313C11.5882 4.19142 10.4028 3.71188 9.10549 3.71188C7.68308 3.71188 6.39559 4.28844 5.46463 5.22008C4.53299 6.15173 3.95643 7.43912 3.95643 8.86094C3.95643 10.2834 4.53299 11.5708 5.46463 12.5018C6.39628 13.4334 7.68367 14.01 9.10549 14.01C10.5279 14.01 11.8154 13.4334 12.7464 12.5018C13.678 11.5702 14.2546 10.2828 14.2546 8.86094C14.2546 8.59445 14.2342 8.33219 14.1955 8.07625L15.5145 6.75719C15.7318 7.41954 15.8492 8.12688 15.8492 8.86103C15.8492 10.723 15.0941 12.409 13.8741 13.6289C12.6542 14.8495 10.968 15.604 9.10626 15.604C7.24452 15.604 5.55828 14.8489 4.33842 13.6289C3.11781 12.409 2.36328 10.7228 2.36328 8.86103C2.36328 6.99929 3.11844 5.31305 4.33842 4.09319C5.55834 2.87258 7.24452 2.11805 9.10626 2.11805L9.10547 2.11719Z" fill="%234DB062"/></svg>');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0 6px 0 0;
  }

  > .extras {
    font-size: 12px;
    > .extras--extra {
      display: flex;
      align-items: center;
      padding: 4px 0;
    }
  }
  > .total {
    > .row {
      color: #019be0;
      font-weight: bold;
      display: flex;
      justify-content: space-between;
      padding: 8px 0;
      font-size: 16px;
    }
    > .total-information {
      font-size: 12px;
      color: #9d9d9d;
    }
  }

  ${props =>
    !props.$expanded &&
    css`
      > :nth-child(n):not(:first-child):not(:last-child) {
        ::after {
          display: none;
        }
      }
      > .invoice {
        // padding: 5px 0;
      }
      > .total {
        padding: 5px 0;
        margin-top: -10px;
      }
    `}
`

export const OrderSummary = (props: IProps) => {
  const name = getReadableInterval(props.price)

  const expanded = typeof props.expanded === "boolean" ? props.expanded : true

  const { price, quantity } = props
  const trialDays = price.recurring.trial_period_days || 7

  const priceLabel = useMemo(() => {
    const tier = getCorrectTier(price, quantity)
    const fullAmount = Math.max(
      tier.up_to * tier.unit_amount + tier.flat_amount,
      0
    )
    if (props.couponObject) {
      const { amountOff, discountPercent } = getAmountOff(
        price,
        props.couponObject
      )
      const discountedAmount =
        Math.max(
          tier.up_to * tier.unit_amount + tier.flat_amount - amountOff,
          0
        ) *
        (1 - discountPercent)

      return (
        <span>
          <s>{formatAmount(props.price.currency, fullAmount)}</s>{" "}
          {formatAmount(props.price.currency, discountedAmount)}
        </span>
      )
    }

    return <span>{formatAmount(props.price.currency, fullAmount)}</span>
  }, [price, quantity, props.couponObject, trialDays])

  return (
    <Wrapper $expanded={expanded}>
      <h2>Order summary</h2>
      <div className="invoice">
        <div className="invoice--row total">
          <div className="invoice--row-label">{name} plan</div>
          <div className="invoice--row-price">
            Pay {formatAmount(price.currency, 0, 0)} today
          </div>
        </div>
        <div className="invoice--row">
          <div className="invoice--row-label">Children x {props.quantity}</div>
          <div className="invoice--row-price">{priceLabel}</div>
        </div>
      </div>
      {expanded && (
        <div className="extras">
          <div className="extras--extra">
            <div className="check-icon" />
            {trialDays > 0 && <div>{trialDays} day free trial</div>}
          </div>
          {price.recurring.interval === "year" &&
            price.product.metadata.includesResources === "true" && (
              <div className="extras--extra">
                <div className="check-icon" />
                <div>A year's worth of printable resources</div>
              </div>
            )}
        </div>
      )}
      <div className="total">
        {expanded && (
          <div className="row">
            <div>Today's total</div>
            <div>{formatAmount(price.currency, 0)}</div>
          </div>
        )}
        <div className="total-information">
          Pay nothing today, then <b>{priceLabel}</b> after the {trialDays} day
          free trial period ends.
        </div>
      </div>
    </Wrapper>
  )
}
