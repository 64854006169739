import React from "react"
import styled from "styled-components"

const Wrapper = styled.div`
  color: #a2a2a2;
  > .highlight {
    color: #5cb46c;
    font-weight: bold;
  }
  margin: 0 0 18px;
  padding: 16px;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  border: 1px solid #4db062;
  color: rgba(0, 0, 0, 0.6);
  text-align: center;
  font-family: "Libre Baskerville";
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
`

export const CancelAnytime = () => {
  return (
    <Wrapper className="cancel-anytime">
      We know your kids will love Night Zookeeper,{" "}
      <span className="highlight">but you are free to cancel at any time.</span>
    </Wrapper>
  )
}
