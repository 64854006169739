import { ICoupon, StripePrice } from "../../types"

export const getReadableInterval = (price: StripePrice) => {
  if (price.recurring.interval === "month") {
    if (price.recurring.interval_count === 1) {
      return "Monthly"
    }
    if (price.recurring.interval_count === 3) {
      return "Quarterly"
    }
  }
  if (price.recurring.interval === "year") {
    return "Yearly"
  }
  return ""
}

export const getCorrectTier = (price: StripePrice, quantity: number) => {
  const { tiers } = price
  const tier = tiers
    // Cleans tiers to remove duplicate flat_amounts and keep the biggest
    .reduce((acc: any, t: any, i: number) => {
      if (acc.length === 0) {
        return [...acc, { ...t, index: i }]
      }
      if (
        tiers[i + 1] &&
        tiers[i + 1].up_to &&
        tiers[i + 1].flat_amount === t.flat_amount
      ) {
        return [...acc, tiers[i + 1]]
      }
      if (t.up_to === null) {
        const maxTier = Math.max(...tiers.map(t => t.up_to))
        return [...acc, { ...t, up_to: maxTier + 1 }]
      }
      return [...acc, t]
    }, [])
    // Searches for maximum matching tier
    .reduce((acc: any, t: any) => {
      if (acc.up_to) return acc
      if (t.up_to && quantity <= t.up_to) {
        return t
      }
      return acc
    }, {})

  return tier
}

export const getAmountOff = (price: StripePrice, coupon: ICoupon) => {
  let discountPercent = 0
  let amountOff = 0
  if (coupon.percent_off) {
    discountPercent = coupon.percent_off / 100
  }
  if (coupon.amount_off && coupon.currency === price.currency) {
    amountOff = coupon.amount_off
  }
  return { discountPercent, amountOff }
}
