import { gql, useApolloClient } from "@apollo/client"
import { useEffect, useState } from "react"

const useFormErrors = formData => {
  const [errors, setErrors] = useState<any>([{}, 0])
  const client = useApolloClient()

  const checkEmail = value => {
    if (!value) return "Please provide a valid email address."
    const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!re.test(value)) {
      return "Please provide a valid email address."
    }
    return null
  }

  const checkEmailIsFree = async () => {
    const { data } = await client.query({
      query: gql`
        query freeEmail($email: String!) {
          freeEmail(email: $email)
        }
      `,
      fetchPolicy: 'network-only',
      variables: {
        email: formData.email,
      },
    })
    if (!data.freeEmail) {
      setErrors(errors => {
        const newErrors = { ...(errors[0] || {}) }
        newErrors.email = "This email is already registered."
        return [newErrors, errors[1]]
      })
    }
    return data.freeEmail
  }

  const checkFirstName = value => {
    if (!value) return "Please add your first name."
    return null
  }

  const checkLastName = value => {
    if (!value) return "Please add your last name."
    return null
  }

  const checkTerms = value => {
    if (!value) return "Please accept the terms and conditions."
    return null
  }

  const checkMarketing = (value, countryCode: string) => {
    if (!value && countryCode !== 'GB') return "Please allow us to contact you so you can receive emails about your account."
    return null
  }

  const checkCountry = value => {
    if (!value) return "Please select a country."
    return null
  }

  const checkChildren = value => {
    if (value.length === 0) return "Please add at least 1 child."
    if (value.filter(d => d === null).length > 0)
      return "Please select an age for all children."
    return null
  }

  useEffect(() => {
    const email = checkEmail(formData.email)
    const firstName = checkFirstName(formData.firstName)
    const lastName = checkLastName(formData.lastName)
    const termsAccepted = checkTerms(formData.termsAccepted)
    const marketingAccepted = checkMarketing(formData.marketingAccepted, formData.countryCode)
    const countryCode = checkCountry(formData.countryCode)
    const childrenAges = checkChildren(formData.childrenAges)

    const errorsArray = [
      email,
      firstName,
      lastName,
      termsAccepted,
      marketingAccepted,
      countryCode,
      childrenAges,
    ].filter(d => d)

    const errors = {
      email,
      firstName,
      lastName,
      termsAccepted,
      marketingAccepted,
      countryCode,
      childrenAges,
    }

    setErrors([errors, errorsArray.length])
  }, [formData])

  return { errors, checkEmailIsFree }
}

export default useFormErrors
