import { StripePrice, StripeTier } from "../../types"

/**
 * Returns the amount and currency of a StripePrice for a given number of children
 * @param price StripePrice
 * @param nbChildren number
 * @returns {amount: number, currency: string}
 * @example getAmountAndCurrency({currency: "eur", tiers: [{flat_amount: 100, unit_amount: 50, up_to: 2}, {flat_amount: 200, unit_amount: 40, up_to: null}]}, 3) // {amount: 280, currency: "eur"}
 */
export const getAmountAndCurrency = (
  price: StripePrice,
  nbChildren: number
) => {
  const { tiers } = price
  const tier = tiers
    // Cleans tiers to remove duplicate flat_amounts and keep the biggest
    .reduce((acc: StripeTier[], t: StripeTier, i: number) => {
      if (acc.length === 0) {
        return [...acc, { ...t, index: i }]
      }
      if (
        tiers[i + 1] &&
        tiers[i + 1].up_to &&
        tiers[i + 1].flat_amount === t.flat_amount
      ) {
        return [...acc, tiers[i + 1]]
      }
      if (t.up_to === null) {
        const maxTier = Math.max(...tiers.map(t => t.up_to))
        return [...acc, { ...t, up_to: maxTier + 1 }]
      }
      return [...acc, t]
    }, [])
    // Searches for maximum matching tier
    .reduce((acc: StripeTier | null, t: StripeTier) => {
      if (acc?.up_to) return acc
      if (t.up_to && nbChildren <= t.up_to) {
        return t
      }
      return acc
    }, null)

  const currency = price.currency
  if (!tier) return { amount: 0, currency }
  const amount = tier.flat_amount + nbChildren * tier.unit_amount

  return { amount, currency }
}

/**
 * Returns the frequency of a StripePrice
 * @param price StripePrice
 * @returns "monthly" | "quarterly" | "yearly" | undefined
 * @example getFrequency({recurring: {interval: "month", interval_count: 1}}) // "monthly"
 */
export const getFrequency = (price: StripePrice) => {
  const { recurring } = price
  if (recurring?.interval === "month" && recurring?.interval_count === 1)
    return "monthly"
  if (recurring?.interval === "month" && recurring?.interval_count === 3)
    return "quarterly"
  if (recurring?.interval === "year") return "yearly"
  return undefined
}
