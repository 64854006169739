import { gql, useApolloClient } from "@apollo/client"
import { useLocation } from "@reach/router"
import Loader from "components/UI/Loader"
import { Link } from "gatsby"
import { FormTrash } from "grommet-icons"
import { Plus } from "nzk-react-components"
import React, { useEffect, useState } from "react"
import { COUNTRIES } from "../../../../../constants"
import useHubspotTracking from "../../../../../hooks/useHubspotTracking"
import Button from "../../../../UI/Button"
import Checkbox from "../../../../UI/Checkbox"
import Input from "../../../../UI/Input"
import Select from "../../../../UI/Select"
import useFormErrors from "./useFormErrors"
import * as s from "./index.styles"
import { OrderSummary } from "../OrderSummary"
import { CancelAnytime } from "../CancelAnytime"

const CHILDREN_AGES_OPTIONS = [
  { value: 5, label: "5 years old" },
  { value: 6, label: "6 years old" },
  { value: 7, label: "7 years old" },
  { value: 8, label: "8 years old" },
  { value: 9, label: "9 years old" },
  { value: 10, label: "10 years old" },
  { value: 11, label: "11 years old" },
  { value: 12, label: "12+ years old" },
]

interface IFormData {
  firstName: string
  lastName: string
  email: string
  countryCode: string
  termsAccepted: boolean
  marketingAccepted: boolean
  childrenAges: number[]
  price: string
  quantity: number
}

interface IProps {
  price: {
    id: string
    currency: string
    nickname: string
    recurring: {
      interval: "month" | "year"
      interval_count: number
      trial_period_days: number
    }
    product: {
      metadata: {
        includesResources: string | null
      }
    }
    tiers: {
      up_to: number
      flat_amount: number
      unit_amount: number
    }[]
  }
  onSubmit: (data: IFormData) => void
  formData: IFormData
  setFormData: (data: any) => void
}

const selectTheme = {
  selectedColor: "#701ea8",
  focusedColor: "#701ea855",
  borderColor: "#701ea8",
}

const ParentSignupForm = (props: IProps) => {
  const [errorsShown, setErrorsShown] = useState(false)
  const location = useLocation()
  const [loading, setLoading] = useState(false)
  const [formData, setFormData] = [props.formData, props.setFormData]
  const {
    errors: [errors, nbErrors],
    // checkEmailIsFree,
  } = useFormErrors(formData)
  const [formError, setFormError] = useState<string | undefined>()

  const { identify } = useHubspotTracking()
  const client = useApolloClient()
  const tiers = props.price.tiers || []

  const MAX_CHILDREN = Math.max(...tiers.map(t => t.up_to)) + 1

  const updateChildAge = (index, age) => {
    setFormData(data => {
      const newChildrenAges = [...data.childrenAges]
      newChildrenAges[index] = age
      return {
        ...data,
        childrenAges: newChildrenAges,
      }
    })
  }

  const addChild = () => {
    setFormData(data => {
      const newChildrenAges = [...data.childrenAges, null]
      return {
        ...data,
        childrenAges: newChildrenAges,
        quantity: newChildrenAges.length,
      }
    })
  }

  const removeChildAtIndex = index => {
    setFormData(data => {
      const newChildrenAges = [...data.childrenAges]
      newChildrenAges.splice(index, 1)
      return {
        ...data,
        childrenAges: newChildrenAges,
        quantity: newChildrenAges.length,
      }
    })
  }

  const submit = async () => {
    if (loading) return
    setErrorsShown(true)
    if (nbErrors > 0) return
    // const free = await checkEmailIsFree()
    // if (!free) return
    setLoading(true)
    identify({
      email: formData.email,
      firstname: formData.firstName,
      lastname: formData.lastName,
      country_code: formData.countryCode,
      contact_type: "Parent",
    })
    const variables = {
      input: {
        name: `${formData.firstName} ${formData.lastName}`,
        email: formData.email,
        countryCode: formData.countryCode,
        nbChildren: formData.childrenAges.length,
        planName: props.price.nickname,
        optOutMarketing: !formData.marketingAccepted,
      },
    }
    const { data, errors } = await client.mutate({
      mutation: gql`
        mutation ($input: RegisterPreSubmitParentSubscriptionInput) {
          register_preSubmitSubscription(input: $input) {
            status
            message
          }
        }
      `,
      variables,
      errorPolicy: "all",
    })

    if (errors && errors.length > 0) {
      setLoading(false)
      setFormError(`An error has occured. Please try again later.`)
      return
    }

    if (data.register_preSubmitSubscription?.status === "ERROR") {
      setLoading(false)
      setFormError(data.register_preSubmitSubscription?.message)
      return
    }
    if (data.register_preSubmitSubscription?.status === "EMAIL_TAKEN") {
      setLoading(false)
      setFormError("This email is already registered.")
      return
    }

    try {
      if (typeof window !== "undefined") {
        window.postMessage(
          {
            event: "ParentRegistrationComplete",
            payload: {
              email: formData.email,
              firstName: formData.firstName,
              lastName: formData.lastName,
              country: formData.countryCode,
              nbChildren: formData.childrenAges.length,
            },
          },
          location.origin
        )
      }
    } catch (err) {
      console.error(err)
    }
    setLoading(false)
    props.onSubmit(formData)
  }

  useEffect(() => {
    if (formData.countryCode === "GB") {
      setFormData(f => ({ ...f, marketingAccepted: false }))
    } else {
      setFormData(f => ({ ...f, marketingAccepted: false }))
    }
  }, [formData.countryCode])

  return (
    <s.Wrapper>
      {(props.price.recurring.trial_period_days || 7) > 0 && (
        <s.Title>
          Start your {props.price.recurring.trial_period_days || 7} day free
          trial
        </s.Title>
      )}
      <s.SectionTitle>Your details</s.SectionTitle>
      {!props.price.tiers && <s.ErrorMessage>Invalid plan</s.ErrorMessage>}
      {errorsShown && errors.firstName && (
        <s.ErrorMessage>{errors.firstName}</s.ErrorMessage>
      )}
      <Input
        placeholder="Your first name"
        autoComplete="given-name"
        value={formData.firstName}
        borderColor="#701ea8"
        onChange={e => setFormData(f => ({ ...f, firstName: e.target.value }))}
      />
      {errorsShown && errors.lastName && (
        <s.ErrorMessage>{errors.lastName}</s.ErrorMessage>
      )}
      <Input
        placeholder="Your last name"
        autoComplete="family-name"
        value={formData.lastName}
        borderColor="#701ea8"
        onChange={e => setFormData(f => ({ ...f, lastName: e.target.value }))}
      />
      {errorsShown && errors.email && (
        <s.ErrorMessage>{errors.email}</s.ErrorMessage>
      )}
      <Input
        placeholder="Your email"
        autoComplete="email"
        value={formData.email}
        borderColor="#701ea8"
        onChange={e => setFormData(f => ({ ...f, email: e.target.value }))}
      />
      {errorsShown && errors.countryCode && (
        <s.ErrorMessage>{errors.countryCode}</s.ErrorMessage>
      )}
      <Select
        value={formData.countryCode}
        theme={selectTheme}
        placeholder="Select your country"
        options={COUNTRIES}
        onChange={value => setFormData(f => ({ ...f, countryCode: value }))}
      />

      {errorsShown && errors.termsAccepted && (
        <s.ErrorMessage>{errors.termsAccepted}</s.ErrorMessage>
      )}
      <s.Condition>
        <Checkbox
          checked={formData.termsAccepted}
          onChange={value => setFormData(f => ({ ...f, termsAccepted: value }))}
          color="#701ea8"
        />
        <p>
          I am over 18 years old and I agree to the following{" "}
          <Link to="/terms-and-conditions">Terms & Conditions</Link> and{" "}
          <Link to="/terms-and-conditions">Privacy Policy</Link>
        </p>
      </s.Condition>
      {errorsShown && errors.marketingAccepted && (
        <s.ErrorMessage>{errors.marketingAccepted}</s.ErrorMessage>
      )}
      <s.Condition>
        <Checkbox
          checked={formData.marketingAccepted}
          onChange={value =>
            setFormData(f => ({ ...f, marketingAccepted: value }))
          }
          color="#701ea8"
        />
        {formData.countryCode === "GB" ? (
          <p>
            I want to receive free monthly learning resources along with
            information about your product and services.
          </p>
        ) : (
          <p>
            I give Night Zookeeper permission to contact me about their products
            and services.
          </p>
        )}
      </s.Condition>
      <s.ChildrenSection>
        <s.SectionTitle>Your children</s.SectionTitle>
        {errorsShown && errors.childrenAges && (
          <s.ErrorMessage>{errors.childrenAges}</s.ErrorMessage>
        )}
        <s.Info>
          <p>Age aligns with school year.</p>
          <p>Lowering age decreases the difficulty.</p>
        </s.Info>
        <s.Children>
          {formData.childrenAges.map((age, i) => (
            <s.Child key={`${i}-${age}`}>
              <Select
                theme={selectTheme}
                value={formData.childrenAges[i]}
                placeholder={`Child ${i + 1} - Select Age`}
                options={CHILDREN_AGES_OPTIONS}
                onChange={value => updateChildAge(i, value)}
              />
              {formData.childrenAges.length > 1 && (
                <s.RemoveChild onClick={() => removeChildAtIndex(i)}>
                  <FormTrash color="#701ea8" />
                </s.RemoveChild>
              )}
            </s.Child>
          ))}
          {formData.childrenAges.length < MAX_CHILDREN && (
            <s.AddChild onClick={() => addChild()}>
              <Button round size="x-small" theme="primary">
                <Plus />
              </Button>
              <span>Add another child</span>
            </s.AddChild>
          )}
        </s.Children>
      </s.ChildrenSection>
      <div className='order-summary'>
      <OrderSummary
        price={props.price}
        quantity={formData.childrenAges.length}
      />
      {formError && (
        <s.FormError>
          <div dangerouslySetInnerHTML={{ __html: formError }} />
        </s.FormError>
      )}
      {loading && <Loader color="#701ea8" size={40} />}
      <div className="submit-button">
        <Button
          theme="primary"
          size="small"
          disabled={nbErrors > 0 || loading}
          onClick={submit}
        >
          {loading ? "Loading..." : "Next"}
        </Button>
      </div>
      <CancelAnytime />
      </div>
    </s.Wrapper>
  )
}

ParentSignupForm.defaultProps = {}

export default ParentSignupForm
