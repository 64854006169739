import { useMutation } from "@apollo/client"
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import Loader from "components/UI/Loader"
import gql from "graphql-tag"
import Cookies from "js-cookie"
import { ArrowLeft } from "nzk-react-components"
import React, { ReactEventHandler, useEffect, useMemo, useState } from "react"
import styled from "styled-components"
// @ts-ignore
import config from "../../../../../../config"
import usePreAppliedCoupon from "../../../../../hooks/usePreAppliedCoupon"
import useReferral from "../../../../../hooks/useReferral"
import Button from "../../../../UI/Button"
import { FormData, ICoupon } from "../../types"
import { CancelAnytime } from "../CancelAnytime"
import { OrderSummary } from "../OrderSummary"
import { Title, Wrapper } from "../ParentSignupForm/index.styles"
import Coupon from "./Coupon"
import StripeSvg from "./Stripe"
import { getAmountAndCurrency, getFrequency } from "./utils"

const BackButton = styled.div`
  position: absolute;
  top: 70px;
  left: 20px;
`

const Section = styled.div`
  > * {
    margin-bottom: 10px;
  }
  &.plan-section {
    margin: 0 -20px 20px -20px;
    background-color: #f8f8f8;
    padding: 0px 20px;
  }

  &.payment-section {
    .informations {
      text-align: center;
      font-size: 0.8em;
      line-height: 1.5;

      ul {
        padding: 0;
        text-align: center;
        list-style-type: none;
      }
    }
    .recurring-billing-information {
      text-align: center;
      font-size: 0.6em;
      line-height: 1.5;
    }
  }
`

const ExpandSummary = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  cursor: pointer;

  margin-top: 20px;
  padding-top: 10px;
  
  ::after {
      position: absolute;
      content: "";
      width: 100%;
      top: 0;
      left: 0;
      height: 1px;
      background-color: #ebebeb;
  }

  > .icon {
    height: 30px;
    width: 30px;
    background-image: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" viewBox="0 0 30 30" fill="none"><path d="M23.4015 9L15.1345 17.4566L6.74131 9.18714L5 10.9916L15.195 21L25.2059 10.7416L23.4015 9Z" fill="%23767676"/></svg>');
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;
    margin: 0;
  }
  &[data-expanded="true"] {
    > .icon {
      transform: rotate(180deg);
    }
  }

`

const SectionTitle = styled.div`
  font-size: 1.4em;
  color: #45106b;
  // font-weight: 700;
  margin-bottom: 16px;
  text-align: center;
`

const PaymentForm = styled.form`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 15px;
  gap: 15px;
  > * {
    width: 100%;
  }
`

const PaymentError = styled.div`
  width: 100%;
  margin-top: 10px;
  background-color: #ec5e2e;
  color: #fff;
  padding: 8px 10px;
  border-radius: 6px;
  text-align: center;
`

interface IProps {
  onBack: () => void
  specialOffer: boolean
  price: {
    id: string
    currency: string
    nickname: string
    product: {
      metadata: any
    }
    recurring: {
      interval: string
      interval_count: number
      trial_period_days: number
    }
    tiers: {
      up_to: number
      flat_amount: number
      unit_amount: number
    }[]
  }
  couponObject?: ICoupon
  formData: FormData
  setFormData: React.Dispatch<React.SetStateAction<FormData>>
}

const ParentCheckoutForm = (props: IProps) => {
  const elements = useElements()
  const stripe = useStripe()
  const code = useReferral()
  const [summaryExpanded, setSummaryExpanded] = useState(false)
  const { formData } = props
  const [paymentError, setPaymentError] = useState<undefined | string>()
  const [loading, setLoading] = useState(false)
  const { clearCoupon } = usePreAppliedCoupon()

  useEffect(() => {
    if (typeof window !== "undefined") {
      // Scroll the page smoothly to the top
      window.scrollTo({ top: 0, behavior: "smooth" })
    }
  }, [])

  const [createSubscription] = useMutation<
    {
      register_createIntent: {
        error?: string
        type?: "SETUP" | "INTENT"
        clientSecret?: string
      }
    },
    {
      input: {
        firstName: string
        lastName: string
        countryCode: string
        email: string
        childrenAges: number[]
        priceId: string
        couponCode?: string
        referralCode?: string
        nzkReferrer?: string
      }
    }
  >(gql`
    mutation createIntent($input: RegisterCreateStripeIntentInput!) {
      register_createIntent(input: $input) {
        type
        clientSecret
        error
      }
    }
  `)

  const setCoupon = (value: string, couponObject: ICoupon) => {
    props.setFormData(f => ({
      ...f,
      couponCode: value,
      couponObject,
    }))
  }

  const handleSubmit: ReactEventHandler<HTMLFormElement> = async e => {
    e.preventDefault()
    if (!elements || !stripe) return

    const { error: submitError } = await elements.submit()

    if (submitError) {
      setPaymentError(submitError.message)
      return
    }

    setLoading(true)

    const { data, errors } = await createSubscription({
      variables: {
        input: {
          email: formData.email,
          priceId: props.price.id,
          countryCode: formData.countryCode,
          referralCode: code || undefined,
          childrenAges: formData.childrenAges || [0],
          couponCode: formData.couponCode,
          firstName: formData.firstName,
          lastName: formData.lastName,
          nzkReferrer: Cookies.get("nzk_referrer"),
        },
      },
      errorPolicy: "all",
    })

    if (!data?.register_createIntent || (errors && errors[0])) {
      let message = "An unknown error has occured."
      if (
        errors &&
        errors[0] &&
        /Email already taken/.test(errors[0].message)
      ) {
        message = "Email already taken"
      }
      setLoading(false)
      setPaymentError(message)
      return
    }

    if (data?.register_createIntent.error) {
      setLoading(false)
      setPaymentError(data.register_createIntent.error)
      return
    }

    clearCoupon()

    const payload = {
      elements,
      clientSecret: data?.register_createIntent.clientSecret,
      confirmParams: {
        return_url: `${window.location.protocol}//${window.location.host}/parents/checkout/complete`,
      },
    }

    const frequency = getFrequency(props.price)

    // @ts-ignore
    window.dataLayer = window.dataLayer || []
    // @ts-ignore
    window.dataLayer.push({
      event: "PaymentInitiated",
      price: {
        id: props.price.id,
        currency: props.price.currency,
        nickname: props.price.nickname,
      },
      plan_frequency: frequency,
    })

    let result: any
    if (data?.register_createIntent.type === "SETUP") {
      result = await stripe.confirmSetup(payload)
      if (result.error) {
        setPaymentError(result.error.message)
      }
      // If the setup succeeded, we redirect to the success page
    } else {
      result = await stripe.confirmPayment(payload)
      if (result.error) {
        setPaymentError(result.error.message)
      }
      // If the payment succeeded, we redirect to the success page
    }
    setLoading(false)
  }

  return (
    <Wrapper>
      <BackButton>
        <Button size="small" theme="primary" round onClick={props.onBack}>
          <ArrowLeft />
        </Button>
      </BackButton>

      <Title>
        Start your {props.price.recurring.trial_period_days || 7} day free trial
      </Title>


      <Section className="plan-section">

      <OrderSummary
      price={props.price}
      quantity={formData.childrenAges.length}
      couponObject={props.formData.couponObject}
      expanded={summaryExpanded}
      />
        {summaryExpanded && !props.specialOffer && (
          <Coupon
            currency={props.price.currency}
            onAppliedCoupon={(coupon, couponObject) =>
              setCoupon(coupon, couponObject)
            }
          />
        )}

        <CancelAnytime />
        <ExpandSummary data-expanded={summaryExpanded} onClick={() => {
        setSummaryExpanded(v => !v)
        }}>
          <div className='icon' />
        </ExpandSummary>
      </Section>

      <Section className="payment-section">
        <SectionTitle>Your card details</SectionTitle>
        <div className="informations">
          <p>
            Your card details are used to verify you are an adult, and <span style={{
            color: '#019BE0',
            fontWeight: 'bold'
            }}>you will{" "}
              <u>not</u>
            {" "}
            be charged until the end of your trial.</span>
          </p>
          <div
            className="stripe-logo"
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "flex-end",
            }}
          >
            <StripeSvg style={{ width: "100px", height: "30px" }} />
          </div>
        </div>
        <PaymentForm onSubmit={handleSubmit}>
          <PaymentElement
            options={{
              applePay: {
                // @ts-ignore
                recurringPaymentRequest: {
                  managementURL: "https://dashboard.nightzookeeper.com/",
                  paymentDescription: "Night zookeeper subscription",
                  regularBilling: {
                    label: "Night Zookeeper Subscription",
                    amount: getAmountAndCurrency(
                      props.price,
                      formData.childrenAges.length
                    ).amount,
                    recurringPaymentIntervalUnit:
                      props.price.recurring.interval,
                    recurringPaymentIntervalCount:
                      props.price.recurring.interval_count,
                    recurringPaymentStartDate: new Date(
                      Date.now() +
                        (props.price.recurring.trial_period_days || 7) *
                          24 *
                          60 *
                          60 *
                          1000
                    ),
                  },
                },
              },
              terms: {
                card: "never",
                paypal: "never",
                applePay: "never",
                googlePay: "never",
              },
            }}
          />
          {loading && <Loader color="#45106b" size={40} />}
          {paymentError && <PaymentError>{paymentError}</PaymentError>}
          <div className="recurring-billing-information">
            {(props.price.recurring.trial_period_days || 7) > 0 && (
              <div>
                Recurring billing will start after your trial ends on the 7th
                day.
              </div>
            )}
            <div style={{ marginTop: "10px" }}>Cancel any time.</div>
          </div>
          <button type="submit" disabled={!stripe || loading}>
            <Button
              theme="primary"
              size="small"
              // onClick={submit}
              disabled={!stripe || loading}
            >
              {loading ? "Loading..." : "Submit"}
            </Button>
          </button>
        </PaymentForm>
      </Section>
    </Wrapper>
  )
}

ParentCheckoutForm.defaultProps = {
  couponObject: undefined,
}

export default (props: IProps) => {
  const stripePromise = useMemo(() => loadStripe(config.stripeKey), [])

  return (
    <Elements
      stripe={stripePromise}
      options={{
        paymentMethodCreation: "manual",
        mode: "subscription",
        amount: 0,
        currency: props.price.currency,
      }}
    >
      <ParentCheckoutForm {...props} />
    </Elements>
  )
}
