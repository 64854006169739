import { navigate } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"
import React from "react"
import styled, { css } from "styled-components"
import { FONT_FAMILIES } from "../../../styles/Theme"
import Button from "../Button"

const Wrapper = styled.div<WrapperProps>`
  position: relative;
  padding: 20px 15px 20px 15px;
  border: 2px solid ${props => props.outlineColor};
  order: ${props => props.order ?? 0};
  @media screen and (max-width: 963px) {
    order: ${props => props.mobileOrder ?? 0};
  }
  background-color: #fff;
  border-radius: 12px;
  display: inline-flex;
  flex-direction: column;
  width: auto;
  text-align: center;
  box-shadow: 0 4px 0 rgba(0, 0, 0, 0.5);
  transition: transform 0.3s;
  transform: scale(1);
  :hover {
    transform: scale(1.1);
  }
  color: #000;
`

const Title = styled.div`
  font-size: 2rem;
  ${(props: { color: string }) => css`
    color: ${props.color};
  `}
`

const Description = styled.div`
  ${(props: { color: string }) => css`
    color: ${props.color};
  `}
`

const Price = styled.div`
  font-size: 1.6rem;
  margin-bottom: 6px;
  margin-top: 12px;
`

const PriceInfo = styled.div`
  font-size: 0.8rem;
  margin-bottom: 15px;
  color: #b2b4b3;
`

const Badge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  height: 70px;
  width: 70px;
  ${FONT_FAMILIES.rammetto};
  font-size: 0.9rem;
  line-height: 1.2;
  padding: 0 10px;
  color: #fff;
  transform: translate(50%, 20%) rotate(20deg);
  background-color: #ff4822;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 5px solid #ff7255;
  box-shadow: 0 4px 0 #bb2000, 0 8px 0 rgba(0, 0, 0, 0.3);
`

const ExtraResources = styled.div`
  position: relative;
  width: 100%;
  pointer-events: none;
  padding-top: 20px;

  padding-bottom: 81px;
  margin-top: -31px;
  > .extra-resources--content {
    color: #000000;
    font-weight: bold;
    max-width: 186px;
    margin: 0 auto;
  }
  > .extra-resources--image {
    position: absolute !important;
    left: 50%;
    transform: translateX(-50%);
    width: 130%;
  }
  img {
    object-fit: contain !important;
  }
`

const Sam = styled.div`
  position: relative;
  height: 140px;
  width: 100%;
  margin-top: -30px;
  pointer-events: none;
  > :first-child {
    position: absolute !important;
    height: 145% !important;
    transform: translateX(-50%);
    width: 100%;
  }
  img {
    object-fit: contain !important;
  }
`

export interface IPlanProps {
  planId: string
  title: string
  description: string
  priceLabel: string
  priceInformation: string
  linkTo?: string
  extraResources: boolean
  button: {
    value: string
    theme?: "primary" | "confirm"
    backgroundColor?: string
  }
  badgeText?: string
  highlighted: boolean
  showSam: boolean
}

interface WrapperProps {
  outlineColor: string
  mobileOrder?: number
  order?: number
}

const Plan = (props: IPlanProps) => {
  const color = props.highlighted ? "#FF4822" : "#B2B4B3"
  let mobileOrder = 0
  let order = 0
  if (props.title.includes("Yearly")) {
    mobileOrder = 1
    order = 2
  } else if (props.title.includes("3 Months")) {
    mobileOrder = 2
    order = 1
  } else if (props.title.includes("Monthly")) {
    mobileOrder = 3
    order = 3
  }

  return (
    <Wrapper
      outlineColor={color}
      onClick={() => props.linkTo && navigate(props.linkTo)}
      order={order}
      mobileOrder={mobileOrder}
    >
      {props.badgeText && <Badge>{props.badgeText}</Badge>}
      <Title color={color}>{props.title}</Title>
      <Description color={color}>{props.description}</Description>
      <Price>{props.priceLabel}</Price>
      <PriceInfo>{props.priceInformation}</PriceInfo>
      {props.button && (
        <Button
          backgroundColor={props.highlighted ? "#FF4822" : "#fff"}
          size="regular"
        >
          {props.button.value}
        </Button>
      )}
      {props.extraResources && (
        <ExtraResources>
          <div className="extra-resources--content">
            <p>Includes a year's worth of printable activities!</p>
          </div>
          <div className="extra-resources--image">
            <StaticImage
              src="../../../images/bundle-multipack.png"
              alt="Resources Bundle"
              placeholder="blurred"
            />
          </div>
        </ExtraResources>
      )}
      {!props.extraResources && props.showSam && (
        <Sam>
          <StaticImage
            src="../../../images/characters/sam-head.png"
            alt="Sam"
            placeholder="blurred"
          />
        </Sam>
      )}
    </Wrapper>
  )
}

Plan.defaultProps = {}

export default Plan
